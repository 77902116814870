/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.3.6,
* Autoprefixer: v10.3.1
* Browsers: last 4 version
*/


@font-face {
    font-family: 'Abril Fatface';
    src: local('Abril Fatface Regular'), local('AbrilFatface-Regular'),
        url('./Assets/Fonts/AbrilFatface-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
        url('./Assets/Fonts/Montserrat-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Bold'), local('Montserrat-Bold'),
        url('./Assets/Fonts/Montserrat-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Italic'), local('Montserrat-Italic'),
        url('./Assets/Fonts/Montserrat-Italic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'),
        url('./Assets/Fonts/Montserrat-BoldItalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}


 * {
   margin: 0;
   padding: 0;
   -webkit-box-sizing: border-box;
           box-sizing: border-box;
   font-family: 'Montserrat', sans-serif;
   font-size: 18px;
   line-height: 23px;
 }

 :root {
  --text-font: 'Montserrat', sans-serif;
  --title-font: 'Abril Fatface', serif;
  --text-color: #171717;
  --secondary-text-color: rgba(117, 117, 117, 1);
  --main-bg-color: #ffffff; 
  --primay-btn-color: #ffffff;
  --primay-btn-bgcolor: #BD2D0A;
  --secondary-btn-color: #826b60;
  --secondary-btn-color-light: #05284fBF;
  --secondary-btn-bgcolor: #ffffff;
  --secondary-btn-color-hover: #ffffff;
  --secondary-btn-bgcolor-hover: #05284f;
  --tertiary-btn-color: #ffffff;
  --tertiary-btn-bgcolor: #fda62b;
  --success-btn: #42bda1;
}

html {
  height: 100%;
  scroll-behavior: smooth !important;
  -ms-scroll-snap-type: y mandatory;
      scroll-snap-type: y mandatory;
  overflow: hidden;

}

 body {
   height: 100%;
   width: 100%;
   background: var(--main-bg-color);
   overflow-x: hidden;
   scroll-behavior: smooth !important;
   -ms-scroll-snap-type: y mandatory;
       scroll-snap-type: y mandatory;
 }

 h1 {
  font-family: var(--title-font);
  font-weight: 400;
  font-style: normal;
  font-size: 3.5rem;
  line-height: 4rem;
  letter-spacing: .5rem;
  color: var(--text-color);
 }

 h2 {
  font-family: var(--text-font);
  font-size: 1.25rem;
  line-height: 1.6rem;
  font-weight: 400;
  color: var(--text-color); 
 }

 h3 {
   font-family: var(--text-font);
   font-size: 1.1rem;
   line-height: 1.6rem;
   font-weight: 600;
   letter-spacing: .06rem;
   color: var(--text-color);
 }

 a {
   text-decoration: none;
   color: var(--success-btn);
 }

 a.navBar-link {
   color: var(--main-bg-color);
 }

 p {
   font-family: var(--text-font);
   overflow-wrap: break-word;
 }

 ol, ul {
   margin-left: 25px !important;
 }

  /* NAVBAR */

 .navBar {
   width: 100%;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-pack: justify;
       -ms-flex-pack: justify;
           justify-content: space-between;
   -webkit-box-orient: horizontal;
   -webkit-box-direction: normal;
       -ms-flex-direction: row;
           flex-direction: row;
   -webkit-box-align: center;
       -ms-flex-align: center;
           align-items: center;
   padding: 10px 20px;
   background-color: transparent;
 }

 .navBar-home-icon {
   position: fixed;
   height: 50px;
   opacity: .6;
   -webkit-transition: all .3s ease-in-out;
   -o-transition: all .3s ease-in-out;
   transition: all .3s ease-in-out;
   z-index: 1;
 }

 .navBar-home-icon:hover {
  opacity: 1;
 }

 .hamburger {
    position: fixed;
    padding: 10px;
    top: 30px;
    right: 25px;
    cursor: pointer;
    z-index: 3;
    -webkit-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
  }
  
 .line {
    height: 4px;
    width: 35px;
    border: none;
    background-color: var(--text-color);
    -webkit-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
  }

.line::before,
.line::after {
  content: '';
  position: absolute;
  width: 35px;
  height: 4px;
  background-color: var(--text-color);
  -webkit-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
}

.line::before {
  -webkit-transform: translateY(-10px);
      -ms-transform: translateY(-10px);
          transform: translateY(-10px);
}
.line::after {
  -webkit-transform: translateY(10px);
      -ms-transform: translateY(10px);
          transform: translateY(10px);
}

/* ANIMATION */
.hamburger.open .line {
  -webkit-transform: translateX(50px);
      -ms-transform: translateX(50px);
          transform: translateX(50px);
  background: transparent;
}
.hamburger.open .line::before {
  -webkit-transform: rotate(45deg) translate(-35px, 35px);
      -ms-transform: rotate(45deg) translate(-35px, 35px);
          transform: rotate(45deg) translate(-35px, 35px);
  background: var(--main-bg-color);
}
.hamburger.open .line::after {
  -webkit-transform: rotate(-45deg) translate(-35px, -35px);
      -ms-transform: rotate(-45deg) translate(-35px, -35px);
          transform: rotate(-45deg) translate(-35px, -35px);
  background: var(--main-bg-color);
}

.menu-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #826b60;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 50%;
  z-index: 2;
  -webkit-transform: translateX(100%);
      -ms-transform: translateX(100%);
          transform: translateX(100%);
  -webkit-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  padding: 50px 0;
}

.menu-bar.open-menu {
  -webkit-transform: translateX(-1%);
      -ms-transform: translateX(-1%);
          transform: translateX(-1%);
}

 .navBar-items {
   height: 100%;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
       -ms-flex-direction: column;
           flex-direction: column;
   -webkit-box-pack: space-evenly;
       -ms-flex-pack: space-evenly;
           justify-content: space-evenly;
   -webkit-box-align: center;
       -ms-flex-align: center;
           align-items: center;
   margin: 0 !important; 
 }

 li.navBar-link-item {
   position: relative;
   z-index: 3;
   font-family: var(--text-font);
   opacity: .6;
   font-weight: 500;
   list-style: none;
   text-decoration: none;
   color: var(--main-bg-color);
   -webkit-transition: all .3s ease-in-out;
   -o-transition: all .3s ease-in-out;
   transition: all .3s ease-in-out;
 }

 .navBar-link-item:hover {
   opacity: 1;
 }

  /* PAGE elements */
.bg-img {
  position: relative;
  top: 0;
  left: 0;
  background-color: #e3dcda;
  width: 100%;
  height: 100vh;
  overflow: visible;
  padding-top: 6rem;
  margin-top: -20px;
  scroll-snap-align: start;
}

  .container {
   width: 100%;
   margin: 0 auto;
   background-color: transparent;
   min-height: 100vh;
   
 }

 #main-home {
   max-width: 1300px !important;
   height: 100%;
   padding: 30px;
   
 }

 .main-img {
   position: absolute;
   bottom: 0;
   right: 10vw;
   height: 100%;
   max-height: 650px;
   overflow: hidden;
   z-index: 0;
   opacity: .9;
 }

 .homepage-title-wrapper {
   position: relative;
   top:15%;
   left: 0;
   z-index: 1;
   padding-left: 4rem;
 }

 #homepage-title {
   position: relative;
   font-size: 4rem;
   padding: 10px 0;
 }

#subhead {
  position: relative;
  color: #ffffff;
  font-size: 2rem;
  letter-spacing: 2.4rem;
  margin-left: .3rem;
  padding: 10px 0 40px 0;
}

#main-cta {
  border-radius: 0;
}

.secondary-inverse {
  font-size: 20px;
  line-height: 26px;
  color: var(--primay-btn-color);
  background-color: var(--secondary-btn-color);
  border: none !important;
  padding: 10px 20px;
  margin-left: 10px;
  opacity: .8;
  -webkit-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.secondary-inverse:hover {
  opacity: 1;
}

.featured {
  scroll-snap-align: center;
  height: 100%;
  min-height: 100vh;
}

#featured-section, #about-section {
   position: relative;
   font-size: 55px;
   line-height: 61px;
   font-weight: 400;
   text-align: center;
   color: var(--text-color);
 }

 #about-section {
   padding: 4rem 0 2rem 0;
 }

#featured {
  padding: 6rem 0 2rem 0;
}

.featured-subhead {
  padding-top: 1.5rem;
}

.featured-block {
  padding: 65px 30px;
  margin-left: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.subhead {
  text-align: center;
  padding-bottom: 1rem;
  color: var(--text-color);
  opacity: .6;
}

.about {
  padding: 0 4rem;
  height: 100%;
  min-height: 90vh;
  scroll-snap-align: start;
}

.about-content-wrapper {
  padding: 50px;
 
}

.about-content {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 50px 0 100px 0;
}

.review {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 40px;

}

img.about-img {
  max-height: 200px;
  width: 100%;
  padding: 30px 0;
}

.about-text {
  font-size: 15px;
  line-height: 20px;
  padding: 0 25px;
}

.secondary-text {
  color: var(--secondary-text-color)
}

/* ARTICLEs PAGE */
#article-bg-img {
  height: 50vh;
  min-height: 400px;
  scroll-snap-align: none !important;
}
  .articlepage-title-wrapper {
   width: 100%;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-pack: center;
       -ms-flex-pack: center;
           justify-content: center;
   padding: 50px 0;
 }

 #articlepage-title {
   position: relative;
   font-size: 4rem;
   line-height:4.5rem;
   text-align: center;
   margin-top: 25px;
   color: var(--text-color);
 }

 .flex-container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 25px 25px 150px 25px;
 }

 .footer-container {
   position: relative;
   bottom: 0;
   background-color: #e3dcda;
   width: 100vw !important;
   height: 50px;
   padding: 25px;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-pack: center;
       -ms-flex-pack: center;
           justify-content: center;
   text-align: center;
   -webkit-box-align: center;
       -ms-flex-align: center;
           align-items: center;
 }

 .footer-title {
   font-family: var(--title-font);
   font-size: 15px;
   line-height: 18px;
 }

 .footer p {
   font-size: 13px;
   line-height: 18px;
   padding-bottom: 10px;
 }

/* 404 page */
 .error-container {
   width: 100%;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-pack: center;
       -ms-flex-pack: center;
           justify-content: center;
   padding: 100px 50px 50px 50px;
 }

 .error-img {
   width: 50%;
   padding: 25px;
 }

 .error-right {
   height: 100%;
   max-height: 300px;
   padding: 25px;
 }

 .error-text {
   width: 50%;
   padding: 25px;
 }

 .error-text p {
   padding: 25px;
 }

 .error-btn {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-pack: center;
       -ms-flex-pack: center;
           justify-content: center;
   padding: 25px 0;
 }

      /* DESKTOP larger than laptop */
      /* If screen size is more than */
      @media only screen and (min-width: 1400px) {
        .container {
        max-width: 1300px !important;
        }

        .flex-container {
          width: 100%;
        }
      }

      @media only screen and (max-width: 1100px) {
        #article-bg-img {
           height: 40vh;
           min-height: 300px;
          }

        .container {
          min-height: 57vh;
        }
      }
      

      /* TABLET & SOME MOBILE layouts */
      /* If screen size is more than */
      @media only screen and (min-width: 1024px) {
        .container {
          max-width: 1300px !important;
        }

        .flex-container {
          width: 100%;
          -webkit-box-pack: space-evenly;
              -ms-flex-pack: space-evenly;
                  justify-content: space-evenly;
        }

        .post {
          margin: 10px !important;
        }

        .post:hover {
          -webkit-transform: translateY(-5px);
              -ms-transform: translateY(-5px);
                  transform: translateY(-5px);
        }

        .post-img:hover {
          -webkit-transform: scale(1.05);
              -ms-transform: scale(1.05);
                  transform: scale(1.05);
        }
      }

      /* TABLET & SOME MOBILE layouts */
      /* If screen size is or less */
      @media only screen and (max-width: 1024px) {
        .container {
          max-width: 1300px !important;
        }

        #main-home {
          padding: 0;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          -webkit-box-pack: start;
              -ms-flex-pack: start;
                  justify-content: flex-start;
          scroll-snap-align: none !important;
        }

       .featured, .about {
          scroll-snap-align: none !important;
        }

        .featured-subhead {
          padding-top: 0;
        }

        .main-img-wrapper {
          width: 100%;
          height: 100vh;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          -webkit-box-align: end;
              -ms-flex-align: end;
                  align-items: flex-end;
        }

        .main-img {
          position: relative !important;
          max-height: 350px;
          overflow: hidden;
          z-index: 0;
          right: 0vw;
        }

        .flex-container {
          width: 100%;
          -webkit-box-pack: space-evenly;
              -ms-flex-pack: space-evenly;
                  justify-content: space-evenly;
        }

        .featured-block {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; 
        -ms-flex-wrap: wrap; 
            flex-wrap: wrap;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        }

        .homepage-title-wrapper {
        width: 100%;
        position: relative;
        z-index: 1;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        padding-left: 0;
        }

        #homepage-title, #articlepage-title {
        font-size: 3rem;
        padding: 10px;
        }

        #subhead {
          font-size: 1.5rem;
          margin-left: 1.4rem;
          letter-spacing: 2rem;
          padding: 10px 10px 40px 10px;
        }

        .post {
          width: 40% !important;
          margin: 25px !important;
        }

        #featured-section, #about-section {
          font-size: 2.5rem;
          line-height: 3rem;
          padding: 6rem 0 2rem 0;
        }

        .about-content {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
        }

        img.about-img {
          width: auto;
        }

        .about-text {
          font-size: 15px;
          line-height: 20px;
          padding-left: 0;
          padding-right: 0;
          max-width: 300px;
        }

        .footer-container {
          padding: 25px;
        }

        .error-container {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          padding: 50px 25px;
        }

        .error-img {
          padding: 25px 0;
        }

        .error-text {
          width: 75%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
        }

        .error-text p, .error-btn {
          padding: 25px 0;
        }
      }

      /* MOBILE smaller than 600px */
      @media only screen and (max-width: 770px) {
        html, body {
          -ms-scroll-snap-type: none;
              scroll-snap-type: none;
        }

        .main-img {
        max-height: 30vh;
        }

        .container {
          padding: 0;
        }

        .flex-container {
          padding: 0;
        }

        .about {
          padding: 0 2rem;
        }

        .featured {
          padding: 0;
          height: 100%;
        }

        #featured {
          padding: 0 !important;
        }

        .postShow-container {
          padding: 25px 30px !important;
        }

      }

        @media only screen and (max-width: 480px) {

          #homepage-title, #articlepage-title {
          font-size: 2rem;
          letter-spacing: .4rem;
          padding: 10px 0;
          }

          #subhead {
            font-size: 1rem;
            margin-left: 1rem;
            letter-spacing: 1.2rem;
            padding: 10px 0 40px 0;
          }

          .featured-block {
            padding: 0;
            margin: 0;
            width: 100%;
            max-width: 100%;
          }

          .articlepage-title-wrapper {
            padding: 0;
          }

          .post {
          width: 100% !important;
          margin: 50px 10 !important;
          }

          #show-heart {
          position: relative !important;
          top: 0 !important;
          left: 60% !important;
          }

          #featured-section, #about-section {
            font-size: 1.5rem;
            line-height: 2rem;
          }

          .subhead {
            font-size: 17px;
            line-height: 22px;
            padding-left: 10px;
            padding-right: 10px;
          }
        }

      @media only screen and (max-width: 380px) {

        #homepage-title {
        font-size: 2.5rem;
        letter-spacing: .1rem;
        line-height: 2.3rem;
        padding: 10px 0;
        }

        #subhead {
          font-size: .9rem;
          letter-spacing: 1rem;
          padding: 10px 0 40px 0;
          margin: 0;
        }

        .error-text {
          width: 100%;
        }
        
        .primary.error-primary {
          font-size: 15px;
          padding: 8px 20px;
        }
      }

      @media only screen and (max-width: 360px) {
        #homepage-title {
        font-size: 2rem;
        letter-spacing: .1rem;
        padding-top: 50px;
        text-align: center;
        }

      }

      @media only screen and (max-width: 320px) {

        #homepage-title {
        font-size: 2rem;
        padding-top: 50px;
        }

        #subhead {
          text-align: center;
          font-size: 1rem;
          margin-right: 0;
          letter-spacing: .9rem;
          padding: 0 0 15px 0;
        }

      }