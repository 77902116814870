/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.3.6,
* Autoprefixer: v10.3.1
* Browsers: last 4 version
*/


.postShow-container {
  width: 100%;
  height: 100%;
  padding: 25px 50px;
  max-width: 800px;
  margin: 0 auto;
}

.show-post {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 50px 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: space-around;
      -ms-flex-align: space-around;
          align-items: space-around; 
}

.show-item {
  padding: 10px 0;
}

.show-item ul, .show-item ol {
  list-style-position: inside;
}

.show-post-header {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 3px;
}

.show-item-likes {
  width: 25%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.heart {
  width: 25px;
  height: 25px;
  overflow: visible;
  cursor: pointer;
  padding: 3px;
  fill: none;
  stroke: var(--text-color);
  stroke-width: 50px;
}

.heart.filled {
  stroke: none;
  stroke-width: 0;
  fill: var(--primay-btn-bgcolor);
}

.show-item-tags {
  width: 75%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.postShow-tag {
  background-color: #05284f;
  opacity: .8;
  color: #ffffff;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: .05em;
  margin: 0 2px;
  border-radius: 5px;
  padding: 3px;
}

.show-title {
  font-weight: 400;
  letter-spacing: .1rem;
  font-size: 45px;
  line-height: 51px;
}

/* ::marker {
  margin-left: 100px !important;
  text-align: end !important;
  text-align-last: end !important;
} */

#showPost-description {
  font-size: 28px;
  line-height: 34px;
}

.postShow-tagImage {
  width: 15px;
  height: 15px;
  margin: 0 2px;
}

.show-author {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #171717;
}

.show-date {
  font-size: 14px;
  line-height: 20px;
  color: rgba(117, 117, 117, 1);
}

.show-image {
  width: 100%;
}

#button-div {
  width: 75%;
}

.space-it {
  margin-right: 20px;
}

.edit {
  padding: 8px 20px;
  font-size: 18px;
  max-width: 100%;
  border-radius: 5px;
  border: none;
  color: #ffffff;
  background-color: #fda62b;
  opacity: .75;
}

.edit:hover {
  opacity: 1;
  -webkit-box-shadow: 3px 3px 3px #d2d2d2;
          box-shadow: 3px 3px 3px #d2d2d2;
}