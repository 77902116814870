/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.3.6,
* Autoprefixer: v10.3.1
* Browsers: last 4 version
*/


.post {
  width: 28%;
  overflow: hidden;
  /* display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; */
  -webkit-box-shadow: 3px 6px 30px rgb(150 155 187 / 40%);
          box-shadow: 3px 6px 30px rgb(150 155 187 / 40%);
  margin-bottom: 45px;
  margin-left: 50px;
  background-color: #ffff;
  cursor: pointer;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.post-item {
  padding-left: 25px;
  padding-right: 25px;
}

.like-wrapper {
  height: 0;
}

.heartOne {
  width: 25px;
  height: 25px;
  position: relative;
  top: 6px;
  left: 80%;
  z-index: 1;
  overflow: visible;
  /* stroke: var(--text-color);
  stroke-width: 50px; */
  fill: #806e658a;
}

.heartOne.filled {
  stroke: none;
  stroke-width: 0;
  fill: var(--primay-btn-bgcolor);
}

.likes {
  position: relative;
  top: -1.4rem;
  left: 90%;
  overflow: visible;
  z-index: 1;
  color: var(--main-bg-color);
}

.post-title {
  padding-top: 25px;
  text-align: left;
  font-family: var(--title-font);
  font-weight: 400;
  font-size: 25px;
  line-height: 40px;
  letter-spacing: .1rem;
  overflow: hidden;
  max-height: 65px;
}

.post-img {
  width: 100%;
  height: 200px;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.post-description {
  padding-top: 20px;
  font-size: 15px;
  line-height: 21px;
  overflow: hidden;
  max-height: 43px;
}

.post-tags {
  padding-top: 15px;
  text-align: right;
  padding-right: 15px;
}

.tag {
  font-size: 13px;
  line-height: 18px;
  margin: 0 2px;
  padding: 3px;
  background-color: #05284f;
  opacity: .8;
  color: #ffffff;
  border-radius: 5px;
  -webkit-box-shadow: 1px 1px 5px #d2d2d2;
          box-shadow: 1px 1px 5px #d2d2d2;
}

.tag-svg {
  height: 15px;
  width: 15px;
  padding: 0 2px;
}

.primary {
  padding: 8px 20px;
  font-size: 18px;
  border-radius: 5px;
  border: none;
  margin: 0 auto;
  color: var(--primay-btn-color);
  background-color: var(--primay-btn-bgcolor);
}

.primary:hover {
  -webkit-box-shadow: 3px 3px 3px #d2d2d2;
          box-shadow: 3px 3px 3px #d2d2d2;
}

.secondary {
  padding: 8px 20px;
  font-size: 18px;
  border: 1px solid var(--secondary-btn-color);
  margin: 0 auto;
  color: var(--secondary-btn-color);
  background-color: var(--main-bg-color);
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.no-hover {
  border: none;
}

.secondary.no-hover:hover {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.secondary:hover {
  -webkit-box-shadow: 3px 3px 3px #d2d2d2;
          box-shadow: 3px 3px 3px #d2d2d2;
}

.post-date {
 padding-top: 10px;
 padding-left: 25px;
 color: #717796;
 font-size: 12px;
 line-height: 18px;
 text-align: left;
 padding-bottom: 25px;
}
