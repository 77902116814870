/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.3.6,
* Autoprefixer: v10.3.1
* Browsers: last 4 version
*/


.postForm-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 50px 10px;
  margin-top: -20px;
}

.postForm {
  -webkit-box-shadow: 3px 6px 30px rgb(150 155 187 / 40%);
          box-shadow: 3px 6px 30px rgb(150 155 187 / 40%);
  height: 90%;
  width: 90%;
  max-width: 810px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 25px;
  background-color: var(--main-bg-color);
  margin-top: 50px;
}

.internalPostForm-alignment {
  height: 100%;
  width: 80%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.postForm-title {
  position: relative;
  text-align: center;
  padding-bottom: 10px;
  color: var(--text-color);
}

.postForm-item {
  padding: 15px 0;
}

.ck-editor__editable {
  height: 500px;
  font-size: 14px;
  line-height: 20px;
  background-color: var(--main-bg-color) !important;
  padding: 25px !important;
}

.ck-file-dialog-button, .ck-toolbar__grouped-dropdown {
  display: none;
}

.CK-placeholder {
  color: #d2d2d2ee;
  font-size: 14px;
  line-height: 20px;
}

input::-webkit-input-placeholder {
  font-size: 14px;
  line-height: 26px;
  color: #d2d2d2ee;
  letter-spacing: .03em;
  background-color: var(--main-bg-color);
}

input::-moz-placeholder {
  font-size: 14px;
  line-height: 26px;
  color: #d2d2d2ee;
  letter-spacing: .03em;
  background-color: var(--main-bg-color);
}

input:-ms-input-placeholder {
  font-size: 14px;
  line-height: 26px;
  color: #d2d2d2ee;
  letter-spacing: .03em;
  background-color: var(--main-bg-color);
}

input::-ms-input-placeholder {
  font-size: 14px;
  line-height: 26px;
  color: #d2d2d2ee;
  letter-spacing: .03em;
  background-color: var(--main-bg-color);
}

input::placeholder {
  font-size: 14px;
  line-height: 26px;
  color: #d2d2d2ee;
  letter-spacing: .03em;
  background-color: var(--main-bg-color);
}


/* Webkit */
[placeholder]:focus::-webkit-input-placeholder { opacity: 0; }
/* Firefox < 19 */
[placeholder]:focus:-moz-placeholder { opacity: 0; }
/* Firefox > 19 */
[placeholder]:focus::-moz-placeholder { opacity: 0; }
/* Internet Explorer 10 */
[placeholder]:focus:-ms-input-placeholder { opacity: 0; }

input {
  width: 100%;
  font-size: 16px;
  line-height: 25px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #171717;
  background-color: var(--main-bg-color) !important;
}

.inputs::-webkit-input-placeholder {
  background-color: var(--main-bg-color) !important;
}

.inputs::-moz-placeholder {
  background-color: var(--main-bg-color) !important;
}

.inputs:-ms-input-placeholder {
  background-color: var(--main-bg-color) !important;
}

.inputs::-ms-input-placeholder {
  background-color: var(--main-bg-color) !important;
}

.inputs, .inputs::placeholder {
  background-color: var(--main-bg-color) !important;
}


.oldImage {
  width: 100%;
  max-width: 600px;
}

.file-upload {
  padding-bottom: 5px;
  padding-top: 15px;
}

#file-upload-input {
  border: none;
}

textarea {
  width: 100%;
  height: 100%;
  max-height: 20rem;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  line-height: 20px;
  resize: vertical;
  white-space: pre-wrap;
}

label {
  font-size: 17px;
  line-height: 22px;
  font-weight: 600;
  padding: 5px 0;
  display: block;
  color: #171717;
  letter-spacing: 1px;
}

.postForm-button {
  width: 100%;
  padding: 10px;
  border: none;
  font-family: var(--text-font);
  font-size: 21px;
  line-height: 27px;
  letter-spacing: 2px;
  color: var(--secondary-btn-bgcolor);
  background-color: var(--secondary-btn-color);
  opacity: .75;
  -webkit-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.postForm-button:hover {
  opacity: 1;
  -webkit-box-shadow: 3px 3px 3px #d2d2d2;
          box-shadow: 3px 3px 3px #d2d2d2;
}

#back-button {
  width: 100%;
}

.tag-list {
  padding: 0 5px;
}

.tag-close-icon {
  width: 15px;
  height: 15px;
  padding-left: 3px;
}

/* form errors messaging */
.error-message-wrapper {
   width: 100%;
   text-align: center;
   background-color: var(--primay-btn-bgcolor);
   color: #ffffff;
   padding: 25px 10px;
   margin: 20px 0;
 }

 .error-message {
   padding-bottom: 20px;
 }

/* form success messaging */

.postForm-button-success {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  font-family: var(--text-font);
  font-weight: 600;
  font-size: 21px;
  line-height: 27px;
  letter-spacing: 2px;
  color: var(--primay-btn-color);
  background-color: var(--success-btn);
}



/* mobile */
@media only screen 
  and (max-device-width: 480px) 
  and (orientation: portrait) { 
  .postForm-container {
    height: 100%;
    padding: 10px;
  }

  .postForm {
        -webkit-box-shadow: none;
        box-shadow: none;
        border-radius: none !important;
        height: 100% !important;
        padding: 10px;
      }
}





